<template>
  <div>
    <b-overlay :show="isFetching">
      <b-button
        variant="primary"
        @click="toggleModal()"
      >
        <feather-icon
          icon="PlusIcon"
        />
        {{ $t('buttons.add') }}
      </b-button>
      <b-card>
        <b-table
          :items="permissions"
          :fields="fields"
          responsive
          show-empty
        >
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(description)="data">
            <PermissionDescriptionForm
              :value="data.item"
              :is-loading="isUpdating"
              @submit="onUpdateDescription"
            />
          </template>
          <template #cell(created_at)="data">
            {{ data.value | datetime }}
          </template>
          <template #cell(id)="data">
            <b-button
              variant="danger"
              size="sm"
              @click="onDelete(data.value)"
            >
              <feather-icon
                icon="Trash2Icon"
              />
            </b-button>
          </template>
          <template #empty="">
            <p class="text-center text-muted">
              {{ $t('messages.nothing_here') }}
            </p>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
    <b-modal
      v-model="isModalActive"
      :title="`${$t('buttons.add')} ${$t('roles.permissions').toLowerCase()}`"
      hide-footer
    >
      <validation-observer
        ref="permissionForm"
      >
        <form @submit.prevent="onSubmit">
          <validation-provider
            v-slot="{ errors }"
            name="name"
            rules="required"
          >
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model.number="permissionForm.name"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </validation-provider>
          <div class="text-right">
            <b-button
              variant="light"
              class="mr-2"
              @click="onCloseModal"
            >
              {{ $t('buttons.cancel') }}
            </b-button>
            <b-overlay
              :show="isAdding"
              rounded
              spinner-small
              class="d-inline-block"
            >
              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ $t('buttons.confirm') }}
              </b-button>
            </b-overlay>
          </div>
        </form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { required } from '@validations'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  page: {
    title: 'Permissions',
  },
  components: {
    PermissionDescriptionForm: () =>
      import('@/views/common-forms/PermissionDescriptionForm.vue'),
  },
  data() {
    return {
      required,
      
      isModalActive: false,
      fields: [
        '#',
        {
          key: 'name',
          label: this.$t('roles.permissions'),
        },
        {
          key: 'description',
          label: this.$t('fields.description'),
        },
        {
          key: 'created_at',
          label: this.$t('fields.createdAt'),
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'id',
          label: this.$t('buttons.remove'),
          class: 'text-right',
        },
      ],
      permissionForm: {
        name: '',
      },
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.role.isFetchingPermissions,
      isAdding: (state) => state.role.isAddingPermission,
      isUpdating: (state) => state.role.isUpdatingPermission,
    }),
    ...mapGetters(['permissions']),
  },
  watch: {},
  created() {
    this.fetchPermissions()
  },
  methods: {
    ...mapActions([
      'fetchPermissions',
      'deletePermission',
      'addPermission',
      'updatePermission',
    ]),
    toggleModal() {
      this.permissionForm.name = ''
      this.isModalActive = !this.isModalActive
    },
    onCloseModal() {
      this.isModalActive = false
    },
    onDelete(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.deletePermission(id)
          }
        })
    },
    onUpdateDescription(data) {
      this.updatePermission({
        id: data.id,
        data,
      })
    },
    async onSubmit() {
      this.$refs.permissionForm.validate().then( async (success) => {
        if (success) {
          await this.addPermission(this.permissionForm)
          this.onCloseModal()
        }
      })
      
    },
  },
}
</script>
